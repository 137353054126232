import axios from 'axios'
import moment from 'moment'

import * as pdfMake from 'pdfmake/build/pdfmake.js'
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js'
import commonServices from './common'

export default {
  getCertficates (profile) {
    return axios.get(process.env.VUE_APP_CERTFICATE + '/candidate/' + profile)
  },

  viewCertficate (id) {
    return axios.get(process.env.VUE_APP_CERTFICATE + '/' + id)
  },

  generateCertficate (data) {
    return axios.post(process.env.VUE_APP_CERTFICATE, data)
  },

  deleteCertficate (id) {
    return axios.delete(process.env.VUE_APP_CERTFICATE + '/' + id)
  },

  getBase64Image (img) {
    const canvas = document.createElement('canvas')
    canvas.width = img.width
    canvas.height = img.height
    const ctx = canvas.getContext('2d')
    ctx.drawImage(img, 0, 0)
    const dataURL = canvas.toDataURL('image/png')
    return dataURL
  },

  downloadCertficate (course, certficate, profile, open) {
    try {
      const certficateLogo = document.getElementById('certficateLogo')
      const cpdLogo = document.getElementById('cpdLogo')
      const ceoSign = document.getElementById('ceoSign')
      const trainerSign = document.getElementById('trainerSign')
      const backpdf = document.getElementById('backpdf')
      pdfMake.vfs = pdfFonts.pdfMake.vfs

      const docDefinition = {
        pageSize: 'A4',
        background: [
          {
            image: this.getBase64Image(backpdf),
            width: 600,
            absolutePosition: { x: 0, y: 0 }
          }
        ],
        content: [
          {
            image: this.getBase64Image(certficateLogo),
            width: 350,
            style: 'logo',
            margin: [0, 110, 0, 0]
          },
          {
            text: 'This is Certify that',
            style: 'content',
            margin: [0, 50, 0, 0]
          },
          {
            text: `${profile.first_name} ${profile.last_name}`,
            color: '#0e3c63',
            fontSize: 30,
            style: 'header',
            margin: [0, 30]
          },
          {
            text: 'Has Successfully Completed the following course:',
            style: 'content'
          },
          {
            text: course.title,
            style: 'header',
            margin: [0, 25, 0, 0]
          },
          {
            columns: [
              {
                text: 'Issue Date : ',
                style: 'content',
                alignment: 'right',
                margin: [0, 25, 0, 0]
              },
              {
                text: moment(certficate.date).format('MM/DD/YYYY'),
                fontSize: 15,
                bold: true,
                margin: [0, 25, 0, 0]
              }
            ]
          },
          {
            image: this.getBase64Image(trainerSign),
            width: 150,
            style: 'logo',
            margin: [0, 40, 0, 0]
          },
          {
            text: 'Michael Wressell',
            style: 'content',
            fontSize: 12,
            margin: [0, 8, 0, 0]
          },
          {
            text: 'Trainer',
            style: 'content',
            margin: [0, 5, 0, 0]
          }
        ],
        footer: {
          image: this.getBase64Image(cpdLogo),
          width: 200,
          style: 'logo',
          absolutePosition: { x: 0, y: -130 }
        },
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            alignment: 'center'
          },
          content: {
            fontSize: 15,
            alignment: 'center'
          },
          logo: {
            alignment: 'center'
          }
        }
      }
      if (open) {
        return pdfMake.createPdf(docDefinition).open()
      } else {
        return pdfMake.createPdf(docDefinition).download(`${course.title}.pdf`)
      }
    } catch (error) {
      console.log('error', error)
      return error
    }
  }
}
